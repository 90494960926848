/**
 * https://wadiz.atlassian.net/wiki/spaces/TF/pages/16505110554/API+Enum#CreationViewStatus-%EC%86%8C%EC%9E%AC-%EC%83%81%ED%83%9C%EA%B0%92
 * 소재 상태값
 */
export const creationViewStatusTable = {
  temp: { name: '등록대기', dot: 'red', tip: '', cmd: '검수요청', updateMsg: '소재 검수를 신청합니다.' },
  insp_none: {
    name: '검수전',
    dot: 'red',
    tip: '아직 와디즈에 검수를 요청하지 않은 소재입니다. 우측 상단의 ‘검수요청‘ 버튼을 눌러 검수를 시작하세요',
    cmd: '검수요청',
    updateMsg: '소재 검수를 신청합니다.',
  },
  insp_ing: {
    name: '검수중',
    dot: 'green',
    tip: '와디즈에서 검수중입니다.',
    cmd: '변경요청',
    updateMsg: '소재 변경을 신청합니다.<br/>검수를 통과하면 신규 소재로 대체됩니다.',
  },
  reg: {
    name: '등록완료',
    dot: 'green',
    tip: '검수를 통과하였습니다.',
    cmd: '변경요청',
    updateMsg: '소재 변경을 신청합니다.<br/>검수를 통과하면 신규 소재로 대체됩니다.',
  },
  reg_insp: {
    name: '재검수중',
    dot: 'green',
    tip: '변경 소재를 와디즈에서 검수중입니다.',
    cmd: '변경요청',
    updateMsg: '소재 변경을 신청합니다.<br/>검수를 통과하면 신규 소재로 대체됩니다.',
  },
  reject: {
    name: '반려',
    dot: 'red',
    tip: '소재가 반려되었습니다. 소재 변경 후 재검수를 요청하세요. <br> * SNS 매체에서 반려되어 재검수 신청 후에도 장시간 반려상태가 유지되면 관리자에게 문의하세요.',
    cmd: '검수요청',
    updateMsg: '소재 검수를 신청합니다.',
  },
  stop_creation_off: {
    name: '중단(소재OFF)',
    dot: 'orange',
    tip: '소재가 OFF되었습니다.',
    cmd: '변경요청',
    updateMsg: '소재 변경을 신청합니다.<br/>중단 사유가 해제되야 노출이 가능합니다.',
  },
  stop_ad_set_off: {
    name: '중단(광고세트OFF)',
    dot: 'orange',
    tip: '광고세트가 OFF되었습니다.',
    cmd: '변경요청',
    updateMsg: '소재 변경을 신청합니다.<br/>중단 사유가 해제되야 노출이 가능합니다.',
  },
  stop_camp_off: {
    name: '중단(캠페인OFF)',
    dot: 'orange',
    tip: '캠페인이 OFF되었습니다.',
    cmd: '변경요청',
    updateMsg: '소재 변경을 신청합니다.<br/>중단 사유가 해제되야 노출이 가능합니다.',
  },
  stop_camp_range: {
    name: '중단(캠페인기간)',
    dot: 'orange',
    tip: '프로젝트 기간이 변경되었습니다. 캠페인 기간을 재설정해주세요.',
    cmd: '변경요청',
    updateMsg: '소재 변경을 신청합니다.<br/>중단 사유가 해제되야 노출이 가능합니다.',
  },
  complete: { name: '종료', dot: 'green', tip: '', cmd: '', updateMsg: '' },
  del: { name: '삭제', dot: 'green', tip: '', cmd: '', updateMsg: '' },
  stop_by_admin: { name: '강제중단(소재OFF)', dot: 'orange', tip: '', cmd: '변경요청', updateMsg: '' },
  reg_insp_after_stop: { name: '재검수중(강제중단)', dot: 'orange', tip: '', cmd: '변경요청', updateMsg: '' },
} as const

export type CreationViewStatusType = keyof typeof creationViewStatusTable
export type CreationViewStatusDescriptionType = (typeof creationViewStatusTable)[CreationViewStatusType]
