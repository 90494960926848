import { forwardRef, Fragment, useMemo, useCallback, useEffect, useRef, useState } from 'react'
import { Modal, Button } from 'antd'
import _ from 'lodash'
import { AIInspectionHistory } from '@biz/api'
import { useAIInspectionHistoryQuery } from '@biz/query'
import { ReactComponent as CloseIcon } from './assets/close-icon.svg'
import CreationAIForm from './CreationAIForm'

import styles from './CreationAIHistoryModal.module.scss'

interface CreationAIHistoryModalProps {
  campId: string | number | undefined
  creationDataId: string | number | undefined
  creationDataGptHisId?: string | number
  setShowCreationChatGptDialog: React.Dispatch<React.SetStateAction<boolean>>
}
// AI 심의 결과 이력 모달
const CreationAIHistoryModal = (
  { creationDataId, creationDataGptHisId, campId, setShowCreationChatGptDialog }: CreationAIHistoryModalProps,
  ref: any
) => {
  const gptFormRef = useRef<any>({})
  // TODO: 현재는 API 마이그레이션 전이라서 어드민 용으로만 사용
  const { data: aiInspectionHistory } = useAIInspectionHistoryQuery(creationDataId, 'admin')
  const [history, setHistory] = useState<AIInspectionHistory[]>([])

  useEffect(() => {
    if (aiInspectionHistory) {
      setHistory(aiInspectionHistory)
    }
  }, [aiInspectionHistory])

  useEffect(() => {
    if (creationDataGptHisId != null && gptFormRef.current[creationDataGptHisId] != null) {
      gptFormRef.current[creationDataGptHisId].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }, [history])

  return (
    <Modal
      open
      title={`AI 심의 결과`}
      width={900}
      closeIcon={<CloseIcon />}
      footer={
        <Button block={false} size="large" onClick={() => setShowCreationChatGptDialog(false)}>
          닫기
        </Button>
      }
      className={styles.container}
      classNames={{
        content: styles.content,
        body: styles.body,
        header: styles.header,
        footer: styles.footer,
      }}
      onCancel={() => setShowCreationChatGptDialog(false)}
    >
      <div id="dialog37" className="dialog">
        <div className="dialog-body">
          <div className="wrap-section wrap-tbl">
            <div className="box-body">
              <div className="tbl">
                <dl className="column-two">
                  <dt>
                    <div className="dt-inner">
                      <span className="fz-16 fc-1">캠페인ID</span>
                    </div>
                  </dt>
                  <dd>
                    <div className="form-group">
                      <span className="comp-txt">
                        <span className="table">
                          <span className="table-cell">
                            <b className="fz-14 fc-2">{campId}</b>
                          </span>
                        </span>
                      </span>
                    </div>
                  </dd>
                  <dt>
                    <div className="dt-inner">
                      <span className="fz-16 fc-1">소재ID</span>
                    </div>
                  </dt>
                  <dd>
                    <div className="form-group">
                      <span className="comp-txt">
                        <span className="table">
                          <span className="table-cell">
                            <b className="fz-14 fc-2">{creationDataId}</b>
                          </span>
                        </span>
                      </span>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="wrap-section wrap-tbl h-500 scroll-y">
            <div className="box-body">
              <div className="tbl">
                <dl className="vertical">
                  <dd>
                    <div className="form-group">
                      {history?.map((history: AIInspectionHistory, index) => (
                        <CreationAIForm
                          key={`${index}-${history.creationDataGptHisId}`}
                          history={history}
                          creationDataId={creationDataId}
                          ref={(e2: any) => {
                            gptFormRef.current[history.creationDataGptHisId] = e2
                          }}
                        />
                      ))}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default forwardRef(CreationAIHistoryModal)
