import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useOutletContext} from "react-router";
import {AgGridReact} from "ag-grid-react";
import {ColDef} from "ag-grid-community";
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from "../common/NoDataTemplate";
import PomNotQualifiedDialog from "./PomNotQualifiedDialog";
import alertify from 'alertifyjs'
import PomInquiryRegDialog from "./PomInquiryRegDialog";
import PomForMakerTab from "./PomForMakerTab";
import PomStatusTab from "./PomStatusTab";
import { PomRestrictionModal } from './PomRestrictionModal'
import PomLimitIncreasementApplicationGuide from './PomLimitIncreasementApplicationGuide'

interface IPomStatus {
  userNo: string
  projectNm: string
  project2Id: string
  pomAvailability: string
  pomLimit: number
  remain: number
  pomUseSum: number
  projectStatusForPom: string
}

interface IPomStatusList {
  ok: boolean
  data: IPomStatus[]
}
const initTooltip = () => {
  $('#pomStatus').tooltip({
    content: '<p class="fz-12 fc-0 fw-medium">펀딩, 프리오더 프로젝트에 한하여 후불머니를 사용할 수 있습니다.</p>',
  })
  $('#pomLimit').tooltip({
    content:
      '<p class="fz-12 fc-0">와디즈 비즈센터를 이용하시는 모든 메이커님들께는 요금제와 펀딩 성공 이력에 따라 한도가 주어집니다.</p>',
  })
  $('#remain').tooltip({
    content:
      '<p class="fz-12 fc-0">후불머니 한도에서 캠페인 생성에 발생한 비용을 제외한 금액입니다. 해당 표에 표기되는 한도 잔액만큼 새로운 캠페인을 세팅하실 수 있습니다.</p>',
  })
  $('#pomUseSum').tooltip({
    content:
      '<p class="fz-12 fc-0 fw-medium">● 매체비+수수료+부가세 포함금액</p>' +
      '<p class="fz-12 fc-0 fw-medium">● 정산예정 금액은 캠페인에서 소진된 금액의 누적 금액을 의미합니다. </p>' +
      '<p class="fz-12 fc-0 fw-medium">● 광고 라이브 후 +4일에 소진된 금액이 확정됩니다. 광고 집행 후 +4일 이후 부터 금액이 소진됩니다. </p>' +
      '<p class="fz-12 fc-0 fw-medium">○ 예) 캠페인 기간이 7/4 ~ 7/8의 경우, 7/4일 소진된 금액은 7/8일에 확정되며, 확정된 날짜부터 일별로 소진됩니다. 따라서 캠페인의 정산은 캠페인 종료 후 4일까지 진행됩니다. </p>',
  })
  $('#projectStatusForPom').tooltip({
    content:
      '<p class="fz-12 fc-0 fw-medium">프로젝트 종료 여부</p><p class="fz-12 fc-0">프로젝트의 시작일 시점 기준으로 시작일이 지나지 않은 경우 ‘진행 전‘ 지난 이후에는 “진행＂으로 표기되며, 종료일 시점이 지난 프로젝트는 “종료”로 표기됩니다.</p>',
  })
}
const PomStatus = () => {
  const projectStatusForPomRef = useRef('ALL')
  const setTitle = useOutletContext<any>()
  const gridRef = useRef<AgGridReact>(null)
  const [isDialogOpened, setIsDialogOpened] = useState(false)
  /** 후불머니 사용 승인 신청 자격 있는지 여부 (신규 메이커 && 화이트 리스트에 등록되지 않아야 함) */
  const [resultData, setResultData] = useState<{ ok?: boolean; data?: string[] }>({})
  const [rowData, setRowData] = useState<any[]>()
  const pomInquiryRegHandler = (project2Id: string, projectNm: string) => {
    setProject2Id(project2Id)
    setProjectNm(projectNm)
    setIsPomLimitIncreasementApplicationGuideDialogOpened(true)
    // setIsPomInquiryRegDialogOpened(true);
  }

  /** 후불문의 세팅 */
  const INQUIRY_CONDITION_OF_POM = 300000
  const [isInitialGuide, setIsInitialGuide] = useState<boolean>(true)
  const [isPomInquiryRegDialogOpened, setIsPomInquiryRegDialogOpened] = useState<boolean>(false)
  const [isPomLimitIncreasementApplicationGuideDialogOpened, setIsPomLimitIncreasementApplicationGuideDialogOpened] =
    useState<boolean>(false)
  const [project2Id, setProject2Id] = useState<string>('')
  const [projectNm, setProjectNm] = useState<string>('')

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'userNo',
      headerName: '사업자등록번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'projectNm',
      headerName: '프로젝트명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      field: 'project2Id',
      headerName: '프로젝트ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'pomStatus',
      headerName: '후불머니 가능 여부',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: (props: any) => {
        const notQualifiedFucn = () => {
          setIsDialogOpened(true)
          setResultData(props.data.resultData)
        }
        if (props.data.pomStatus === 'COMPLETE') return '종료'
        else if (props.data.pomStatus === 'QUALIFIED') return '조건 충족'
        else return <button onClick={() => notQualifiedFucn()}>조건 미충족</button>
      },
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="pomStatus" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
    },
    {
      field: 'pomLimit',
      headerName: '한도액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: any) => {
        if (props.data.pomStatus === 'COMPLETE' || props.data.pomLimit === 0 || props.data.pomLimit === undefined)
          return '-'
        else return props.data.pomLimit.toLocaleString()
      },
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="pomLimit" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
    },
    {
      field: 'remain',
      headerName: '한도 잔액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: any) =>
        props.data.remain === 0 ||
        props.data.remain === undefined ||
        (props.data.pomStatus !== 'QUALIFIED' && props.data.pomUseSum === 0) ||
        (props.data.pomStatus !== 'QUALIFIED' && props.data.pomUseSum === undefined)
          ? '-'
          : props.data.remain.toLocaleString(),
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="remain" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
    },
    {
      field: 'pomUseSum',
      headerName: '정산예정 금액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: any) =>
        props.data.pomUseSum === 0 || props.data.pomUseSum === undefined ? '-' : props.data.pomUseSum.toLocaleString(),
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="pomUseSum" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
    },
    {
      field: 'projectStatusForPom',
      headerName: '프로젝트 종료 여부',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: (props: any) => {
        if (props.data.projectStatusForPom === 'COMPLETE') return '종료'
        else if (props.data.projectStatusForPom === 'ING') return '진행중'
        else if (props.data.projectStatusForPom === 'BEFORE_ING') return '진행 전'
        else return 'null'
      },
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="projectStatusForPom" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
    },
    {
      field: 'pomInquiry',
      headerName: '한도관련 문의',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: (props: any) => {
        let flag = false
        /** BIZ-452 에서 30만원 조건 소거*/
        if (
          /*props.data.remain as number <= INQUIRY_CONDITION_OF_POM && */ props.data.projectStatusForPom === 'ING' &&
          props.data.pomStatus === 'QUALIFIED'
        ) {
          flag = true
        }
        return (
          <button
            type="button"
            className={flag ? 'btn btn-primary xsmall' : 'btn btn-primary-outline xsmall'}
            disabled={!flag}
            onClick={() => pomInquiryRegHandler(props.data.project2Id, props.data.projectNm)}
          >
            신청
          </button>
        )
      },
    },
  ])
  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      suppressMovable: true,
      sortable: true,
    }),
    []
  )
  const onGridReady = useCallback(() => {
    ApiUtil2.get<IPomStatusList>('/api/pom/status').then((resp) => {
      let data = resp.data.data
      if (projectStatusForPomRef.current === 'COMPLETE') {
        data = data.filter((v) => v.projectStatusForPom === 'COMPLETE')
      } else if (projectStatusForPomRef.current === 'ING') {
        data = data.filter((v) => v.projectStatusForPom === 'ING')
      } else if (projectStatusForPomRef.current === 'BEFORE_ING') {
        data = data.filter((v) => v.projectStatusForPom === 'BEFORE_ING')
      }
      setRowData(data)
      initTooltip()
    })
  }, [])

  useEffect(() => {
    setTitle('후불머니 현황')
    /** Select2 */
    $('#srchType')
      .select2({
        width: '200px',
        minimumResultsForSearch: Infinity,
        placeholder: '선택하세요.',
      })
      .on('select2:select', (e) => {
        projectStatusForPomRef.current = e.params.data.id
        onGridReady()
      })
    initTooltip()
    return () => {
      setTitle('')
    }
  }, [])
  return (
    <Fragment>
      {isPomLimitIncreasementApplicationGuideDialogOpened ? (
        <PomLimitIncreasementApplicationGuide
          setIsPomLimitIncreasementApplicationGuideDialogOpened={setIsPomLimitIncreasementApplicationGuideDialogOpened}
          setIsPomInquiryRegDialogOpened={setIsPomInquiryRegDialogOpened}
          isInitialGuide={isInitialGuide}
        />
      ) : null}
      {isPomInquiryRegDialogOpened ? (
        <PomInquiryRegDialog
          setIsPomInquiryRegDialogOpened={setIsPomInquiryRegDialogOpened}
          project2Id={project2Id}
          projectNm={projectNm}
          setIsPomLimitIncreasementApplicationGuideDialogOpened={setIsPomLimitIncreasementApplicationGuideDialogOpened}
          setIsInitialGuide={setIsInitialGuide}
        />
      ) : null}
      {/* {isDialogOpened ? <PomNotQualifiedDialog setIsDialogOpened={setIsDialogOpened} resultData={resultData} /> : null} */}
      {isDialogOpened && (
        <PomRestrictionModal
          isOpen={isDialogOpened}
          onClose={() => setIsDialogOpened(false)}
          reasonList={resultData?.data as unknown as string[]}
        />
      )}
      <PomStatusTab />
      <div className="comp-tab-content selected">
        <section className="wrap-section wrap-datagrid">
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">프로젝트 종료 여부</p>
                  </div>
                  <div className="box-filter">
                    <select className="select2-single w-150" id="srchType">
                      <option value="ALL" defaultChecked={true}>
                        전체
                      </option>
                      <option value="COMPLETE">종료</option>
                      <option value="ING">진행</option>
                      <option value="BEFORE_ING">진행 전</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-body">
            <div className="ag-grid">
              <div className="ag-grid-inner">
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowHeight={48}
                  domLayout={'autoHeight'}
                  noRowsOverlayComponent={NoDataTemplate}
                ></AgGridReact>
              </div>
            </div>
          </div>
          {rowData === undefined ? <NoDataTemplate /> : null}
        </section>
      </div>
    </Fragment>
  )
}
export default PomStatus;
