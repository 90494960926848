import { Form } from 'antd'
import alertify from 'alertifyjs'
import {
  useCampaignStatus,
  useCurrentCampaignIdQuery,
  useCreativeDeleteMutation,
  usePreviewImageMutation,
  useFileImageMutation,
  useUploadIdQuery,
  type ImageMetaDataType,
} from '@biz/query'
import { targetFormRegistry } from '@biz/ui'
import { ImagePanel } from './ImagePanel'

const { CreativeAssetsName, CreativeAssetsId, ImageManagerName } = targetFormRegistry
type ImageManagerFunction = {
  containerName: number
  disabled?: boolean
}
export const ImageManager: React.FC<ImageManagerFunction> = ({ containerName, disabled }) => {
  const { isBeforeInspection } = useCampaignStatus()
  const { data: campId } = useCurrentCampaignIdQuery()
  const { data: uploadId } = useUploadIdQuery()
  const deleteImage = useCreativeDeleteMutation()
  const insertPreviewImage = usePreviewImageMutation()
  const insertFileImage = useFileImageMutation()

  const dependOnTag = [CreativeAssetsName, containerName, CreativeAssetsId]
  return (
    <Form.Item dependencies={[dependOnTag]}>
      {({ getFieldValue, setFieldValue }) => {
        const creationDataId = getFieldValue(dependOnTag) // creationData.id
        const doDelete = (afId: string | number) => {
          const imageTag = [CreativeAssetsName, containerName, ImageManagerName]
          const beforeImageList = getFieldValue(imageTag)
          if (!!campId) {
            if (!isBeforeInspection?.() && beforeImageList.length < 2) {
              alertify.error('소재당 하나 이상의 이미지/동영상 파일을 등록해 주세요')
              return
            }
            deleteImage.mutateAsync({ afId, campId, creationDataId }).then((res) => {
              if (res.ok) {
                const imageList: ImageMetaDataType[] = getFieldValue(imageTag)
                setFieldValue(
                  imageTag,
                  imageList.filter((img) => img.id !== afId)
                )
              }
            })
          }
        }
        const doRegisterImage = (_: ImageMetaDataType[], confirmImage?: ImageMetaDataType) => {
          const afId = confirmImage?.id
          if (!campId || !confirmImage) {
            return
          }
          if (confirmImage.fileId === undefined) {
            insertPreviewImage.mutateAsync({
              afId,
              campId,
              creationDataId,
              gifDiv: confirmImage.gifStyle,
            })
          } else {
            const { afMeta, afName, fileId, fileName, fileSize, gifStyle: gifDiv } = confirmImage || {}
            const { fileDiv, height, width } = afMeta || {}
            insertFileImage.mutateAsync({
              campId,
              creationDataId,
              uploadFile: {
                afName,
                fileDiv,
                fileId,
                fileName,
                fileSize,
                gifDiv,
                height: Number(height),
                width: Number(width),
                publicUrl: fileName,
                uploadId,
              },
            })
          }
        }
        return (
          <Form.Item name={[containerName, ImageManagerName]}>
            <ImagePanel
              onDelete={deleteImage.isLoading ? () => undefined : doDelete}
              onChange={doRegisterImage}
              disabled={disabled}
            />
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}
