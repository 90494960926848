import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { fetchSystemPrivateTerm, fetchSystemServiceTerm, type TermType } from '@biz/api'

type UseFunctionType = () => UseQueryResult<{ private: TermType[]; service: TermType[] }>
export const useTermListQuery: UseFunctionType = () =>
  useQuery({
    queryKey: ['@apps/biz-center/TermList'],
    queryFn: () =>
      Promise.all([fetchSystemPrivateTerm(), fetchSystemServiceTerm()]).then((res) => {
        const [privateRes, serviceRes] = res
        return {
          private: privateRes?.ok === true ? privateRes?.data : [],
          service: serviceRes?.ok === true ? serviceRes?.data : [],
        }
      }),
  })
