import { axios } from '../axios'

type ResponseType = {
  ok: boolean
  data: boolean
}
type FunctionType = (data: {
  systemDataDiv: 'PRIVATE_DATA' | 'TERMS'
  version: string
  text: string
}) => Promise<ResponseType>
export const fetchPostNewTerm: FunctionType = (data) => axios.post('/api/community/systemData/post', data)
