import { Modal, Button } from 'antd'

import styles from './PomRestrictionModal.module.scss'

type PomRestrictionModalFunction = {
  isOpen: boolean
  onClose: () => void
  reasonList: string[]
}
export const PomRestrictionModal: React.FC<PomRestrictionModalFunction> = ({ isOpen, onClose, reasonList }) => (
  <Modal
    width={600}
    open={isOpen}
    closable={false}
    title={<div className={styles.modalTitle}>{'불가사유'}</div>}
    footer={[
      <div className={styles.modalFooter}>
        <Button type="default" block size="large" onClick={onClose} key={'닫기'} style={{ width: 150 }}>
          {'닫기'}
        </Button>
      </div>,
    ]}
    className={styles.containPomRestrictionModal}
  >
    <pre className={styles.content}>{(reasonList || [])?.join('\n')}</pre>
  </Modal>
)
