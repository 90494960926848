import { redirect, type RouteObject } from 'react-router-dom'
import type { QueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { DefaultLayout } from '../DefaultLayout'
import { TermsListPage } from '.'
import { PrivateTermsPage } from './TermPage'
import { ServiceTermsPage } from './TermPage'

type FunctionType = (queryClient: QueryClient) => RouteObject
export const getTermsLayoutRoute: FunctionType = (queryClient: QueryClient) => ({
  element: <DefaultLayout />,
  children: [
    {
      path: '/community/systemData',
      element: <TermsListPage />,
    },
    { path: '/community/systemData/privateDataPost', element: <PrivateTermsPage /> },
    { path: '/community/systemData/termsPost', element: <ServiceTermsPage /> },
  ],
})
