import { useCallback, useState } from 'react'
import { DatePicker, type DatePickerProps } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { toast } from 'react-toastify'
import { ReactComponent as CalendarOutline } from '@common/svg/calendarOutline.svg'
import { useCampaignStatus } from '@biz/query'
import { UpdateConfirmModal } from '@biz/ui'
import styles from './RangePicker.module.scss'

type CellRenderFactory = (targetDate: Dayjs, cls: string) => DatePickerProps<Dayjs>['cellRender']
type RangePickerFunction = {
  value?: [Dayjs | undefined, Dayjs | undefined]
  onChange?: (dates: [Dayjs | undefined, Dayjs | undefined]) => void
  disabled: boolean
}
export const RangePicker: React.FC<RangePickerFunction> = ({ onChange, value, disabled }) => {
  const { isFinished, inOperation, isAdmin } = useCampaignStatus()
  const [changeConfirmData, setChangeConfirmData] = useState<{
    oldContent: string
    newContent: string
    updateCallback: () => void
  } | null>(null)
  const doChange = useCallback(
    (props: [Dayjs | undefined, Dayjs | undefined]) => {
      if (
        value?.[0] === undefined ||
        value?.[1] === undefined ||
        props?.[0] === undefined ||
        props?.[1] === undefined
      ) {
        return
      }

      if (!inOperation?.()) {
        onChange?.(props)
        return
      }

      const timeFormat = 'YYYY.MM.DD HH:mm'
      setChangeConfirmData({
        oldContent: `${value[0].format(timeFormat)} ~ ${value[1].format(timeFormat)}`,
        newContent: `${props[0].format(timeFormat)} ~ ${props[1].format(timeFormat)}`,
        updateCallback: () => onChange?.(props),
      })
    },
    [onChange, inOperation, value]
  )
  const disabledTime = (limitFn: () => Dayjs) => (current: Dayjs) => {
    const limit = limitFn()
    if (!current || current.diff(limit.startOf('day'), 'day') > 0) {
      return {
        disabledHours: () => [],
      }
    }
    const limitHour = limit.hour()
    const limitMinute = limit.minute()
    return {
      /* 배열에 언급되는 숫자가 disabled 됨,  '분'이 50분을 넘으면 해당 시간은 선택할 수 없어서 1을 더함. */
      disabledHours: () => Array.from({ length: limitHour + (limitMinute > 50 ? 1 : 0) }, (_, i) => i),
      /*
      limitHour > selectedHour는 불가능함. disabledHours 조건에서 막혀있음.
      limitHour === selectedHour는 disabled 할 분단위를 선택해야 함. (예 45분이면 [0, 10, 20, 30, 40])
      limitHour < selectedHour(limitHour !== selectedHour)는 모두 enabled 해야 함.  => []
      */
      disabledMinutes: (selectedHour: number) =>
        limitHour !== selectedHour ? [] : [0, 10, 20, 30, 40, 50].filter((m) => m < limitMinute),
    }
  }

  const cellRenderFactory: CellRenderFactory = (targetDate, cls) => (current) => {
    if (typeof current === 'number') {
      // 시간/분 랜더
      return <div className="ant-picker-time-panel-cell-inner">{current}</div>
    }
    const currentDate = current as Dayjs
    const sameDate = currentDate.isSame(targetDate, 'day')
    const rangeDate = currentDate.isAfter(value?.[0], 'day') && currentDate.isBefore(value?.[1], 'day')
    return (
      <div className={`ant-picker-cell-inner ${sameDate ? cls : rangeDate ? styles.selectedDate : ''}`}>
        {(current as Dayjs)?.date?.()}
      </div>
    )
  }

  const [pickerOpenStatus, setPickerOpenStatus] = useState<null | 'first' | 'second'>(null)
  return (
    <div className={styles.containRangePicker}>
      <DatePicker
        allowClear={false}
        minuteStep={10}
        showNow={false}
        showTime={{
          format: 'HH:mm',
          defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
        }}
        format="YYYY-MM-DD HH:mm"
        style={{ width: '100%' }}
        suffixIcon={<CalendarOutline style={{ width: 16, color: '#495057' }} />}
        cellRender={cellRenderFactory(value?.[1] || dayjs(), styles.selectedEndDate)}
        disabled={disabled || isFinished?.()}
        disabledDate={(current) => current && current < dayjs().add(120, 'minute').startOf('day')}
        disabledTime={disabledTime(() => dayjs().add(120, 'minute'))}
        onChange={(d) => {
          const limit = d.add(36, 'hour')
          const difference = limit.diff(value?.[1])
          if (difference > 0) {
            toast.info(`최소 기간을 충족하도록, 광고 종료 일자를 ${limit.format('MM월 DD일')}로 수정합니다.`)
            doChange([d, limit])
          } else {
            doChange([d, value?.[1]])
          }
        }}
        value={value?.[0]}
        open={pickerOpenStatus === 'first'}
        onOpenChange={(status) => {
          if (!status || pickerOpenStatus === 'second') {
            return
          }
          setPickerOpenStatus('first')
        }}
        onOk={() => {
          setPickerOpenStatus(null)
        }}
      />
      <div style={{ height: '100%' }}>{'~'}</div>
      <DatePicker
        allowClear={false}
        minuteStep={10}
        showNow={false}
        showTime={{
          format: 'HH:mm',
          defaultValue: dayjs('11:59:59', 'HH:mm:ss'),
        }}
        format="YYYY-MM-DD HH:mm"
        style={{ width: '100%' }}
        suffixIcon={<CalendarOutline style={{ width: 16, color: '#495057' }} />}
        cellRender={cellRenderFactory(value?.[0] || dayjs(), styles.selectedStartDate)}
        disabled={disabled || isFinished?.()}
        disabledDate={(current) => current && current < (value?.[0] || dayjs()).add(2 + 36, 'hour').startOf('day')}
        disabledTime={disabledTime(() => dayjs().add(2 + 36, 'hour'))}
        onChange={(d) => {
          const limit = d.subtract(36, 'hour')
          const difference = limit.diff(value?.[0])
          if (difference < 0) {
            toast.info(`최소 기간을 충족하도록, 광고 시작 일자를 ${limit.format('MM월 DD일')}로 수정합니다.`)
            doChange([limit, d])
          } else {
            doChange([value?.[0], d])
          }
        }}
        value={value?.[1]}
        open={pickerOpenStatus === 'second'}
        onOpenChange={(status) => {
          if (!status || pickerOpenStatus === 'first') {
            return
          }
          setPickerOpenStatus('second')
        }}
        onOk={() => {
          setPickerOpenStatus(null)
        }}
      />
      <UpdateConfirmModal
        isOpen={changeConfirmData !== null}
        title={'캠페인 기간을 수정하시겠어요?'}
        term={'기간'}
        onClose={() => setChangeConfirmData(null)}
        onConfirm={() => {
          changeConfirmData?.updateCallback()
          setChangeConfirmData(null)
        }}
        oldContent={changeConfirmData?.oldContent || ''}
        newContent={changeConfirmData?.newContent || ''}
      />
    </div>
  )
}
