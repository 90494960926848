import React, { useState, useEffect } from 'react'
import { useLoaderData, useParams } from 'react-router'
import { Tabs, Form } from 'antd'
import { PageTitle, targetFormRegistry } from '@biz/ui'
import { CampaignInfoTab, type CampaignInfoTabFieldsType } from './CampaignInfoTab'
import { TargetInfoTab } from './TargetInfoTab'
import { CreativeAssetsTab } from './CreativeAssetsTab'
import { useConfirm } from './_hooks/useConfirm'
import { CreationEditingNotifyModal } from './CreationEditingNotifyModal'
import { BlockNavigationOnEditingModal } from './BlockNavigationOnEditingModal'
import styles from './TargetCreatePage.module.scss'

const { CampaignNameOfName, ProjectSelectorName, CampaignTotalName } = targetFormRegistry
const tabTable = {
  step01: 'CampaignInfo',
  step02: 'TargetInfo',
  step03: 'CreativeInfo',
} as const
type TabIndexType = keyof typeof tabTable
type TabType = (typeof tabTable)[TabIndexType]
const TargetCreatePageImpl: React.FC<{ title: string }> = ({ title }) => {
  const params = useParams()
  const { initData, tabIndex, isEditingCreation } = useLoaderData() as {
    initData: CampaignInfoTabFieldsType
    tabIndex: TabIndexType
    isEditingCreation: boolean
  }
  const [isEditingNotifyOpen, setEditingNotifyOpen] = useState(isEditingCreation)
  const [activeTab, setTab] = useState<TabType>(tabTable[tabIndex] || 'CampaignInfo')
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(initData)

    setTab(tabTable[tabIndex] || 'CampaignInfo')

    if (!!params?.campId) {
      window.history.replaceState(null, '', `/mngCamp/mngCamp/mng/${params.campId}`)
    }
  }, [form, initData, tabTable[tabIndex], params])

  const { doConfirm, ConfirmComponent } = useConfirm(initData)
  return (
    <Form
      form={form}
      // initialValues={initData as CampaignInfoTabFieldsType}
      onFinish={doConfirm}
      className={styles.containTargetCreatePage}
    >
      <PageTitle>{title}</PageTitle>
      <Form.Item dependencies={[CampaignNameOfName, ProjectSelectorName, CampaignTotalName]}>
        {({ getFieldValue }) => {
          const projectId = getFieldValue(ProjectSelectorName)
          return (
            <>
              <Tabs
                className={styles.tabs}
                activeKey={activeTab}
                onChange={(key) => setTab(key as TabType)}
                items={[
                  {
                    key: 'CampaignInfo',
                    label: '캠페인 정보 입력',
                    children: <CampaignInfoTab onNext={() => setTab('TargetInfo')} />,
                  },
                  {
                    key: 'TargetInfo',
                    label: '타겟 정보 입력',
                    children: (
                      <TargetInfoTab onPrev={() => setTab('CampaignInfo')} onNext={() => setTab('CreativeInfo')} />
                    ),
                    disabled: !projectId,
                  },
                  {
                    key: 'CreativeInfo',
                    label: '소재 정보 입력',
                    children: <CreativeAssetsTab projectId={projectId} onPrev={() => setTab('TargetInfo')} />,
                    disabled: !projectId,
                  },
                ]}
                size="large"
              />
              <ConfirmComponent projectId={projectId} />
            </>
          )
        }}
      </Form.Item>
      <CreationEditingNotifyModal
        isOpen={isEditingNotifyOpen}
        onClose={() => {
          setEditingNotifyOpen(false)
          setTab('CreativeInfo')
        }}
      />
      <BlockNavigationOnEditingModal
        onCancel={() => {
          setTab('CreativeInfo')
        }}
      />
    </Form>
  )
}

export const TargetCreatePage = React.memo(TargetCreatePageImpl)
