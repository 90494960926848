import { useDeleteTermMutation, useTermDetailMutation } from '../../_queries'

export const useTermsOp = () => {
  const deleteMutation = useDeleteTermMutation()
  const detailMutation = useTermDetailMutation()

  return {
    deleteFn: (systemDataId: number) => deleteMutation.mutateAsync({ systemDataId }),
    detailFn: (systemDataId: number) => detailMutation.mutateAsync({ systemDataId }),
  }
}
