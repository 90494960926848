import { Modal, Button } from 'antd'
import { ReactComponent as ErrorIcon } from '@common/svg/error.svg'
import { thousandsGrouping } from '@common/utils'
import styles from './BudgetUpdateNoticeModal.module.scss'

export type BudgetUpdateInfoData = {
  campaignName: string
  rptTotalCost: number
  reducedBudget: number
  beforeCampBudgetTotal: number // 사용자가 입력한 값
  afterCampBudgetTotal: number
  cost: number
}
type BudgetUpdateNoticeModalFunction = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  info: BudgetUpdateInfoData | null
}
export const BudgetUpdateNoticeModal: React.FC<BudgetUpdateNoticeModalFunction> = ({
  isOpen,
  onClose,
  onConfirm,
  info,
}) =>
  !!info && (
    <Modal
      width={600}
      open={isOpen}
      closable={false}
      title={
        <div className={styles.modalTitle}>
          <div>캠페인 예산을 수정하시겠어요?</div>
        </div>
      }
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onClose}>
            {'취소'}
          </Button>
          <Button type="primary" block size="large" onClick={onConfirm}>
            {'수정'}
          </Button>
        </div>,
      ]}
      className={styles.containBudgetUpdateNoticeModal}
    >
      <div className={styles.customBody}>
        <div className={styles.subList}>
          <div>기존 예산</div>
          <div>{thousandsGrouping(info.beforeCampBudgetTotal || 0)}원</div>
        </div>
        <div className={styles.subList}>
          <div>변경 요청 예산</div>
          <div>
            <strong>{thousandsGrouping(info.afterCampBudgetTotal || 0)}원</strong>
          </div>
        </div>
        <div className={styles.bottomLine}></div>
        <div className={styles.infoList}>
          <div>현재 소진 금액</div>
          <div>{thousandsGrouping(info.rptTotalCost || 0)}원</div>
        </div>
        <div className={styles.infoList}>
          <div>감액 가능 예산</div>
          <div>{thousandsGrouping(info.reducedBudget || 0)}원</div>
        </div>
        <div className={styles.warningBox}>
          <div className={styles.warningText}>
            <ErrorIcon />
            다음과 같은 경우 캠페인 예산 감액 요청이 거절될 수 있어요
          </div>
          <ul className={styles.contentList}>
            <li>현재 소진된 금액보다 적거나 비슷한 경우 (매체와의 연동 딜레이로 실 소진 금액은 더 클 수 있음) </li>
            <li>기간, 소재 수를 계산하여 최소 예산 금액이 충족되지 않는 경우 (소재 1개당 일 최소 예산 5,000원)</li>
          </ul>
        </div>
        <div className={styles.grayBox}>
          <ul className={styles.contentList}>
            <li>감액 요청하는 금액이 현재 소진된 금액보다 30% 이상 금액인 경우 감액이 가능합니다.</li>
            <li>감액 실패 시 알림으로 안내드리며 기존 예산으로 변경됩니다. (캠페인 자동 OFF 처리됨)</li>
          </ul>
        </div>
      </div>
    </Modal>
  )

