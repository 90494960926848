import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { nanoid } from 'nanoid'
import { FixedSizeList as List } from 'react-window'
import { useImageListQuery, type ImageMetaDataType } from '@biz/query'
import { MediaThumb } from './MediaThumb'
import styles from './ImageList.module.scss'

type ImageListFunction = {
  onSelect: (imageInfo: ImageMetaDataType) => void
}
export const ImageList: React.FC<ImageListFunction> = ({ onSelect }) => {
  const { data: imageList, isLoading } = useImageListQuery()
  const itemCount = Math.floor((imageList?.length || 1) / 2)
  return (
    <section className={styles.containImageList}>
      {isLoading && (
        <div className={styles.container}>
          <div className={styles.spinContainer}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
          </div>
        </div>
      )}
      {!isLoading && (
        <List className={styles.container} height={600} itemCount={itemCount} itemSize={148} width={300}>
          {({ index, style }) => {
            const n = imageList?.[index * 2]
            const n2 = imageList?.[index * 2 + 1]
            return (
              <div style={style} className={styles.imageCoupleItem}>
                {!!n && <MediaThumb key={nanoid()} mediaData={n} onSelect={() => onSelect(n)} />}
                {!!n2 && <MediaThumb key={nanoid()} mediaData={n2} onSelect={() => onSelect(n2)} />}
              </div>
            )
          }}
        </List>
      )}
    </section>
  )
}
