import React, { Fragment, useEffect } from 'react'
import Editor from 'ckeditor5-custom-build/build/ckEditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ApiUtil, ApiUtil3 } from '@biz/api'

/** 전역변수 */
let uploadId
let fileId
let presignUrl
let publicUrl

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  upload() {
    return (
      this.loader.file
        // eslint-disable-next-line no-async-promise-executor
        .then(
          (file) =>
            new Promise(async (resolve, reject) => {
              await this._initRequest(file)
              await this._initListeners(resolve, reject, file)
              await this._sendRequest(file)
            })
        )
    )
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  async _initRequest(file) {
    // eslint-disable-next-line no-multi-assign
    const xhr = (this.xhr = new XMLHttpRequest())

    /* eslint no-underscore-dangle: 0 */
    const data = { uploadId, originFileName: file.name, myS3Upload: 'board' }

    await ApiUtil3.post('/api/file/uploadReq', data).then((resp) => {
      fileId = resp.data.data.fileId
      presignUrl = resp.data.data.presignUrl
      publicUrl = resp.data.data.publicUrl

      console.log('resp is :', resp)
      console.log('fileId:', fileId)
      xhr.responseType = 'json'
      xhr.open('PUT', presignUrl, true) // xhr url에다가 s3 url 반환해줘야 함.
      xhr.setRequestHeader('Access-Control-Allow-Headers', '*')
    })
  }

  _initListeners(resolve, reject, file) {
    const { xhr } = this
    const { loader } = this
    const genericErrorText = `Couldn't upload file: ${file.name}.`

    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    // eslint-disable-next-line consistent-return
    xhr.addEventListener('load', () => {
      if (xhr.status !== 200) {
        return reject(xhr && xhr.error ? xhr.error.message : genericErrorText)
      }

      resolve({
        default: publicUrl,
      })
    })

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
      })
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _sendRequest(file) {
    this.xhr.send(file)
  }
}
function MyCustomUploadAdapterPlugin(editor) {
  // eslint-disable-next-line no-param-reassign
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    new MyUploadAdapter(loader)
}

const CkEditor5 = (props) => {
  const changeHandler = (editor) => {
    // eslint-disable-next-line react/prop-types
    props.setText(editor.getData())
  }
  useEffect(() => {
    ApiUtil.get('/api/common/getUploadId').then((response) => {
      uploadId = response.data.data
    })
  }, [])
  return (
    <Fragment>
      <div className="row">
        <div className="document-editor__toolbar"></div>
      </div>
      <CKEditor
        /* eslint-disable-next-line react/prop-types */
        data={props.defaultValue}
        editor={Editor}
        onReady={(editor) => {
          document.querySelector('.document-editor__toolbar').appendChild(editor.ui.view.toolbar.element)
          editor.editing.view.change((writer) => {
            writer.setStyle('height', '600px', editor.editing.view.document.getRoot())
          })
        }}
        config={{
          toolbarLocation: 'top',
          extraPlugins: [MyCustomUploadAdapterPlugin],
          removePlugins: ['MediaEmbed', 'link'],
        }}
        onChange={(event, editor) => {
          changeHandler(editor)
        }}
      />
    </Fragment>
  )
}

export default CkEditor5
