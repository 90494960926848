import { Input, Form } from 'antd'
import { targetFormRegistry } from '@biz/ui'
import styles from './InputAdCopy.module.scss'

type InputAdProps = {
  containerName: number
  onUpdate?: () => void
  disabled?: boolean
}
export const InputAdCopy: React.FC<InputAdProps> = ({ containerName, onUpdate, disabled }) => (
  <Form.Item name={[containerName, targetFormRegistry.InputAdCopyName]} className={styles.InputAdCopy}>
    <Input.TextArea
      maxLength={600}
      showCount
      autoSize={{ minRows: 10, maxRows: 10 }}
      placeholder="광고를 설명할 문구를 입력하세요 (선택)"
      onBlur={onUpdate}
      disabled={disabled}
    />
  </Form.Item>
)
1
