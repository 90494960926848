import React, { forwardRef, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { PlainObject } from 'select2'
import { Button } from 'antd'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useMutation } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import { LabelBadge } from '@platform/share/ui'
import { ApiUtil2, AIInspectionHistory } from '@biz/api'
import { AIInspectionHistoryContent } from './AIInspectionHistoryContent'
import styles from './CreationAIForm.module.scss'

interface CreationAIFormProps {
  history: AIInspectionHistory
  creationDataId: string | number | undefined
}

// AI 심의 결과
const CreationAIForm = (props: CreationAIFormProps, ref: any) => {
  const { history } = props
  const { register, setValue, getValues } = useForm()

  const textCheckRef = useRef<HTMLInputElement>(null)
  const imgCheckRef = useRef<HTMLInputElement>(null)
  const movieCheckRef = useRef<HTMLInputElement>(null)
  const slideCheckRef = useRef<HTMLInputElement>(null)
  const adminInspStatusSelect = useRef<HTMLSelectElement>(null)

  const [memoLength, setMemoLength] = useState<number>(0)
  const [adminInspDivDesc, setAdminInspDivDesc] = useState('유형')

  const clearMemo = () => {
    setValue('adminMemo', '')
    setMemoLength(0)
  }

  const checkAdminInspDiv = () => {
    let dropdownTxt = '유형'
    let checkedCnt = 0
    let checkedArr = []

    if (slideCheckRef.current!!.checked) {
      dropdownTxt = '슬라이드'
      checkedCnt++
      checkedArr.push('slide')
    }

    if (movieCheckRef.current!!.checked) {
      dropdownTxt = '영상'
      checkedCnt++
      checkedArr.push('movie')
    }

    if (imgCheckRef.current!!.checked) {
      dropdownTxt = '이미지'
      checkedCnt++
      checkedArr.push('img')
    }

    if (textCheckRef.current!!.checked) {
      dropdownTxt = '텍스트'
      checkedCnt++
      checkedArr.push('text')
    }

    setAdminInspDivDesc(`${dropdownTxt}${checkedCnt > 1 ? ` 외 ${checkedCnt}` : ''}`)
    setValue('adminInspDiv', checkedArr.reverse().join(','))
  }

  const renderGptInstStatus = (chatGptStatus: any) => {
    switch (chatGptStatus) {
      case 'ing':
        return <LabelBadge size="md" value="검수중" color="blue" variant="tint" />
      case 'error':
        return <LabelBadge size="md" value="실패" color="gray" variant="tint" />
      case 'ok':
        return <LabelBadge size="md" value="승인" color="mint" variant="tint" />
      case 'fail':
        return <LabelBadge size="md" value="반려" color="red" variant="tint" />
      default:
        return <LabelBadge size="md" value="검수중" color="blue" variant="tint" />
    }
  }

  const postAdminGptMemo = useMutation(
    (formData: any) => ApiUtil2.post(`/api/mngAd/creation/chatGptHis/saveMemo`, formData),
    {
      onSuccess: (resp) => {
        alertify.success('정상적으로 저장 되었습니다.')
      },
    }
  )

  const saveAdminGptMemo = () => {
    const formData = {
      ...getValues(),
    }

    if (!formData.adminMemo) {
      alertify.error('검수 결과 사유를 입력해 주신 후 다시 시도해 주세요.')
      return
    }

    if (!formData.adminInspStatus || !formData.adminInspDiv) {
      alertify.error('검수 결과와 검수 소재 유형을 선택하신 후 다시 시도해 주세요.')
      return
    }

    postAdminGptMemo.mutate(formData)
  }

  useEffect(() => {
    setValue('creationDataGptHisId', history.creationDataGptHisId)
    setValue('adminInspStatus', history.adminInspStatus ?? '')

    if (history.adminInspStatus) {
      adminInspStatusSelect.current!!.value = history.adminInspStatus
    }

    if (history.adminInspDiv) {
      setValue('adminInspDiv', history.adminInspDiv)
      const adminInspDivs = history.adminInspDiv.split(',')

      _.each(adminInspDivs, (v: string) => {
        switch (v) {
          case 'text':
            textCheckRef.current!!.checked = true
            break
          case 'img':
            imgCheckRef.current!!.checked = true
            break
          case 'movie':
            movieCheckRef.current!!.checked = true
            break
          case 'slide':
            slideCheckRef.current!!.checked = true
            break
        }
      })

      checkAdminInspDiv()
    } else {
      setValue('adminInspDiv', '')
    }

    $(adminInspStatusSelect.current as PlainObject)
      .select2({
        width: '100px',
        minimumResultsForSearch: Infinity,
        dropdownCssClass: 'small',
        placeholder: '검수결과',
      })
      .on('select2:select', (e: any) => {
        setValue('adminInspStatus', e.params.data.id)
      })
  }, [])

  useEffect(() => {
    if (history.adminMemo) {
      setValue('adminMemo', history.adminMemo)
      setMemoLength(history.adminMemo.length)
    }
  }, [history.adminMemo])

  return (
    <div className={`wrap-section wrap-tbl ${styles.container}`} ref={(e) => ref(e)}>
      <div className="box-header">
        <div className="box-tit">
          <h2 className="fz-20 fc-1 fw-bold">AI</h2>
          {renderGptInstStatus(history.gptInspStatus)}
        </div>
        <div className="box-option">
          <h3 className="fz-12 fc-3">
            최종 {history.gptInspStatus == 'ing' || history.gptInspStatus == 'error' ? '전송' : '수신'}일시 :{' '}
            {history.gptInspStatus == 'ing' || history.gptInspStatus == 'error'
              ? dayjs(history.regTime).format('YYYY-MM-DD HH:mm:ss')
              : dayjs(history.completeTime).format('YYYY-MM-DD HH:mm:ss')}
          </h3>
        </div>
      </div>
      <div className={`box-body ${styles.body}`}>
        <div className="tbl">
          <div className={`${styles.aiContent}`}>
            <div className={styles.section}>
              {history.gptInspStatus == 'ok' ? (
                '검수한 모든 항목이 승인 되었습니다.'
              ) : history.comments ? (
                // 신규 AI 검수 결과
                <AIInspectionHistoryContent comments={history.comments} />
              ) : !!history.gptInspContent ? (
                // 이전 ChatGPT 검수 결과
                <p>{history.gptInspContent}</p>
              ) : (
                ''
              )}
            </div>
          </div>

          <dl className={`vertical ${styles.adminMemo}`}>
            <dt>
              <div className="dt-inner flex-container">
                <div className="box-left">
                  <span className="fz-16 fc-1">광고사업팀</span>
                </div>
                <div className={`box-right ${styles.filters}`}>
                  <select className="select2-single small" {...register('adminInspStatus')} ref={adminInspStatusSelect}>
                    <option></option>
                    <option value="ok">승인</option>
                    <option value="fail">반려</option>
                    <option value="ing">검수중</option>
                  </select>
                  <div className="comp-dropdown select2 w-150">
                    <a className="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                      <div className="box-left">
                        <span className="fz-14">{adminInspDivDesc}</span>
                      </div>
                      <div className="box-right">
                        <i className="ico ico-arrow"></i>
                      </div>
                    </a>
                    <div className="dropdown-menu expand">
                      <ul className="opt-selectbox">
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input
                              type="checkbox"
                              id={`txt_chk_${history.creationDataGptHisId}`}
                              onChange={checkAdminInspDiv}
                              value={`text`}
                              ref={textCheckRef}
                            />
                            <label htmlFor={`txt_chk_${history.creationDataGptHisId}`}>텍스트</label>
                          </div>
                        </li>
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input
                              type="checkbox"
                              id={`img_chk_${history.creationDataGptHisId}`}
                              onChange={checkAdminInspDiv}
                              value={`img`}
                              ref={imgCheckRef}
                            />
                            <label htmlFor={`img_chk_${history.creationDataGptHisId}`}>이미지</label>
                          </div>
                        </li>
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input
                              type="checkbox"
                              id={`movie_chk_${history.creationDataGptHisId}`}
                              onChange={checkAdminInspDiv}
                              value={`movie`}
                              ref={movieCheckRef}
                            />
                            <label htmlFor={`movie_chk_${history.creationDataGptHisId}`}>영상</label>
                          </div>
                        </li>
                        <li className="opt-menu">
                          <div className="comp-checkbox small">
                            <input
                              type="checkbox"
                              id={`slide_chk_${history.creationDataGptHisId}`}
                              onChange={checkAdminInspDiv}
                              value={`slide`}
                              ref={slideCheckRef}
                            />
                            <label htmlFor={`slide_chk_${history.creationDataGptHisId}`}>슬라이드</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </dt>
            <dd className={styles.adminFooter}>
              <div className="form-group">
                <div className="input-group small expand">
                  <div className="inner-input-group">
                    <textarea
                      className="tf-textarea"
                      placeholder="해당 소재를 검수하신 결과 및 사유를 입력해 주세요."
                      maxLength={100}
                      {...register('adminMemo')}
                      onChange={(e) => setMemoLength(e.target.value.length)}
                    />
                    <i className="ico ico-delete" onMouseDown={clearMemo}></i>
                  </div>
                  <p className="fz-12 fc-3">
                    <span className="fc-2">{memoLength}</span> / 100
                  </p>
                  <p className="fz-12">
                    <i className="ico"></i>체크 / 에러 문구 내용 영역
                  </p>
                </div>
              </div>
              <Button className={styles.saveMemoButton} onClick={saveAdminGptMemo}>
                저장
              </Button>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(CreationAIForm)
