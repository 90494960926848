import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { fetchPostNewTerm } from '@biz/api'

type FunctionType = () => UseMutationResult<
  unknown,
  unknown,
  { systemDataDiv: 'PRIVATE_DATA' | 'TERMS'; version: string; text: string }
>
export const useTermMutation: FunctionType = () =>
  useMutation({
    mutationFn: ({ systemDataDiv, version, text }) =>
      fetchPostNewTerm({
        systemDataDiv,
        version,
        text,
      }),
  })
