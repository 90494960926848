import { useNavigate } from 'react-router'
import { Form, Input, Button } from 'antd'
import { PageTitle, CkEditor5N } from '@biz/ui'
import { useTermMutation } from './TermMutation'
import styles from './TermPage.module.scss'

const EditorItem: React.FC<{ value?: string; onChange?: (v: string) => void }> = ({ value, onChange }) => (
  <div>
    <CkEditor5N setText={onChange as () => void} data={value || ''} />
  </div>
)
const StarLabel: React.FC<{ name: string }> = ({ name }) => (
  <span>
    {name}
    <span style={{ fontWeight: 400 }}>
      (<span style={{ color: '#ff0000' }}>*</span>)
    </span>
  </span>
)
type TermPageFunction = {
  title: string
  termType: 'PRIVATE_DATA' | 'TERMS'
}
const MaxCount = 5
export const TermPage: React.FC<TermPageFunction> = ({ title, termType }) => {
  const navigate = useNavigate()
  const termMutation = useTermMutation()
  const onFinish = (v: any) => {
    termMutation.mutateAsync({ ...v, systemDataDiv: termType }).finally(() => {
      navigate('/community/systemData')
    })
  }
  return (
    <div className={styles.containTermPage}>
      <PageTitle>{`${title} 신규 작성`}</PageTitle>
      <Form onFinish={onFinish} className={styles.form}>
        <Form.Item
          label={<StarLabel name="버전" />}
          name="version"
          className={styles.label}
          help={`숫자+마침표(.) 조합으로 최대 ${MaxCount}자까지 입력 가능`}
          rules={[
            {
              required: true,
              message: `숫자+마침표(.) 조합으로 최대 ${MaxCount}자까지 입력 가능`,
            },
            {
              pattern: /^([0-9.]+)$/,
              message: '숫자+마침표(.) 조합으로 입력하세요',
            },
            {
              max: MaxCount,
              message: `최대 ${MaxCount}자까지 입력할 수 있어요`,
            },
          ]}
        >
          <Input style={{ width: 400 }} placeholder="1.2.3" />
        </Form.Item>
        <Form.Item
          label={<StarLabel name="내용" />}
          name="text"
          className={`${styles.editorContainer} ${styles.label}`}
          rules={[{ required: true, message: '${another} is required' }]}
        >
          <EditorItem />
        </Form.Item>
        <div className={styles.buttonContainer}>
          <Button type="default" size="large" style={{ width: 200 }} href={'/community/systemData'}>
            취소
          </Button>
          <Button type="primary" size="large" htmlType="submit" style={{ width: 200 }}>
            등록
          </Button>
        </div>
      </Form>
    </div>
  )
}
