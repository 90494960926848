import { type ImageMetaDataType } from '@biz/query'
import styles from './MediaThumb.module.scss'

type MediaThumbFunction = {
  onSelect: () => void
  mediaData: ImageMetaDataType
}
export const MediaThumb: React.FC<MediaThumbFunction> = ({ mediaData, onSelect }) => {
  const src = mediaData.fileName
  const mediaType = mediaData?.afMeta?.fileDiv === 'VIDEO' && mediaData?.afMeta?.ext !== 'gif' ? 'VIDEO' : 'IMAGE'
  const name = mediaData.afName
  const caption = `${mediaData?.afMeta?.width || ''}x${mediaData?.afMeta?.height || ''}`
  return (
    <div className={styles.containMediaThumb}>
      {mediaType === 'VIDEO' ? <video autoPlay controls muted loop src={src} /> : <img src={src} alt={name} />}
      <div className={styles.info} onClick={onSelect}>
        {caption}
      </div>
    </div>
  )
}
