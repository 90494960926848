import { Modal, Button } from 'antd'
import { ReactComponent as ErrorIcon } from '@common/svg/error.svg'
import { ReactComponent as RightIcon } from '@common/svg/chevronRight.svg'
import styles from './UpdateConfirmModal.module.scss'

type UpdateConfirmModalFunction = {
  title: string
  term: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  oldContent: string
  newContent: string
}
export const UpdateConfirmModal: React.FC<UpdateConfirmModalFunction> = ({
  isOpen,
  title,
  term,
  onClose,
  onConfirm,
  oldContent,
  newContent,
}) => {
  return (
    <Modal
      width={600}
      open={isOpen}
      closable={false}
      title={<div className={styles.modalTitle}>{title}</div>}
      footer={[
        <div className={styles.modalFooter}>
          <Button type="default" block size="large" onClick={onClose} key={'닫기'}>
            {'취소'}
          </Button>
          <Button type="primary" block size="large" onClick={onConfirm} key={'수정'}>
            {'수정'}
          </Button>
        </div>,
      ]}
      className={styles.containUpdateConfirmModal}
    >
      <div className={styles.customBody}>
        <div className={styles.subList}>
          <div>{`기존 ${term}`}</div>
          <div>{oldContent}</div>
        </div>
        <div className={styles.subList}>
          <div>{`변경 ${term}`}</div>
          <div>
            <strong>{newContent}</strong>
          </div>
        </div>
        <div className={styles.warningBox}>
          <div className={styles.warningText}>
            <ErrorIcon />
            <div>
              캠페인 진행 중에 기간을 변경할 경우 <strong>머신 러닝이 다시 시작</strong> 됩니다.
            </div>
          </div>
          <a
            href="https://wabiz-static-prod.s3.ap-northeast-2.amazonaws.com/static/usingGuide/%EC%99%80%EB%94%94%EC%A6%88+%ED%83%80%EA%B2%9F%EA%B4%91%EA%B3%A0+%EC%A0%84%EB%9E%B5%EA%B0%80%EC%9D%B4%EB%93%9C.pdf"
            target="_blank"
          >
            FAQ 보기 <RightIcon style={{ width: 16, height: 16 }} />
          </a>
        </div>
      </div>
    </Modal>
  )
}
