import { useState, useCallback } from 'react'
import { Tag } from 'antd'
import { toast } from 'react-toastify'
import { nanoid } from 'nanoid'
import alertify from 'alertifyjs'

import { ReactComponent as CloseIcon } from '@common/svg/close.svg'

import { type GeoInfoType } from '@biz/api'
import { useCachedLocationListQuery, useCampaignStatus } from '@biz/query'
import { UpdateConfirmModal } from '@biz/ui'

import { GeoListSelect } from './GeoListSelect'

import styles from './LocationInput.module.scss'

type LocationInputFunction = {
  value?: string[]
  onChange?: (v: string[]) => void
}
export const LocationInput: React.FC<LocationInputFunction> = ({ value, onChange }) => {
  const { isFinished, inOperation, isAdmin } = useCampaignStatus()
  const { update, dryUpdate, remove } = useCachedLocationListQuery()

  const tagList = value || []

  const [changeConfirmData, setChangeConfirmData] = useState<{
    oldContent: string
    newContent: string
    updateCallback: () => void
  } | null>(null)
  const checkBeforeChange = useCallback(
    (oldList: string[], newList: string[], updateCallback: () => void) => {
      if (!inOperation?.()) {
        updateCallback()
        return
      }
      setChangeConfirmData({
        oldContent: `${oldList.join(',')}`,
        newContent: `${newList.join(',')}`,
        updateCallback,
      })
    },
    [inOperation]
  )

  const doChange = useCallback(
    async (sel: GeoInfoType) => {
      if (tagList.includes(sel.name)) {
        toast.warn('이미 선택된 지역입니다.')
        return
      }
      checkBeforeChange(tagList, dryUpdate(sel), () => onChange?.(update(sel)))
    },
    [tagList]
  )

  const disabled = isFinished?.() || isAdmin?.() || false
  const doDelete = useCallback(
    (e: React.MouseEvent<HTMLElement>, sel: string) => {
      if (disabled || !tagList || tagList.length <= 1) {
        e.preventDefault()
        alertify.error('최소 1개 이상의 지역이 입력되어야 합니다.')
        return
      }
      const targetContent = tagList.filter((item) => item !== sel)
      checkBeforeChange(tagList, targetContent, () => {
        remove(sel)
        onChange?.(targetContent)
      })
    },
    [onChange, tagList, remove]
  )
  return (
    <div
      className={styles.container}
      style={{ opacity: disabled ? '0.5' : '1', pointerEvents: disabled ? 'none' : 'auto' }}
    >
      <GeoListSelect onSelected={doChange} disabled={disabled} />
      <div className={styles.tags}>
        {tagList.map((n, i) => (
          <Tag
            key={nanoid()}
            closable={true}
            onClose={(e) => doDelete(e, n)}
            className={styles.tag}
            closeIcon={<CloseIcon />}
          >
            {n}
          </Tag>
        ))}
      </div>
      <UpdateConfirmModal
        isOpen={changeConfirmData !== null}
        title={'캠페인 타겟을 수정하시겠어요?'}
        term={'지역'}
        onClose={() => setChangeConfirmData(null)}
        onConfirm={() => {
          changeConfirmData?.updateCallback()
          setChangeConfirmData(null)
        }}
        oldContent={changeConfirmData?.oldContent || ''}
        newContent={changeConfirmData?.newContent || ''}
      />
    </div>
  )
}
