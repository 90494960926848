import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import type { GeoInfoType } from '@biz/api' // source type
import { useCampaignAppendInfoMutation, loadAdditionalCampaignInfo, loadCachedLocationList } from '@biz/query'

export const useDefaultAdditionalCampaignInfo = () => {
  const queryClient = useQueryClient()
  const updateCampaign = useCampaignAppendInfoMutation()

  return useCallback(async () => {
    const locationList = loadCachedLocationList(queryClient)()
    const location = ['대한민국']
      .map((name) => {
        const foundLocationData = (locationList || []).find((n) => n?.name === name)
        return foundLocationData
      })
      .filter((n) => !!n) as GeoInfoType[]

    const campaignData = await loadAdditionalCampaignInfo(queryClient)()
    const { adSetExtYn, id: adSetId, campId } = campaignData || {}
    return updateCampaign.mutateAsync({
      location,
      maxAge: '65+',
      minAge: '20',
      genderDiv: ['MALE', 'FEMALE'],
      adSetExtYn,
      adSetId,
      campId,
    })
  }, [updateCampaign])
}
