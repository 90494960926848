import { Modal, Button } from 'antd'
import ReactHtmlParser from 'html-react-parser'

import styles from './DetailViewModal.module.scss'

type DetailViewModalFunction = {
  title?: string
  content?: string
  date?: string
  isOpen: boolean
  onClose: () => void
}
export const DetailViewModal: React.FC<DetailViewModalFunction> = ({ isOpen, onClose, title, content, date }) => (
  <Modal
    width={1000}
    open={isOpen}
    onCancel={onClose}
    title={
      <div className={styles.modalTitle}>
        {title}
        <span className={styles.date}>{date || ''}</span>
      </div>
    }
    footer={[
      <div className={styles.modalFooter}>
        <Button type="default" block size="large" onClick={onClose} key={'닫기'} style={{ width: 150 }}>
          {'닫기'}
        </Button>
      </div>,
    ]}
    className={styles.containDetailViewModal}
  >
    <section className={styles.content}>{ReactHtmlParser(content || '')}</section>
  </Modal>
)
