import { axios } from '../axios'
import type { GeoInfoType } from './fetchGeoList'

export type CampaignAppendInfo = Partial<{
  adSetExtYn: boolean // false
  adSetId: number // 1787672555
  campId: number // 1787672554
  genderDiv: ('MALE' | 'FEMALE')[] // ["MALE", "FEMALE"]
  location: GeoInfoType[]
  maxAge: string // "63"
  minAge: string // "19"
}>

type ResponseType = {
  ok: boolean
  data: boolean
}
type FunctionType = (data: CampaignAppendInfo) => Promise<ResponseType>
export const fetchPostCampaignAppendInfo: FunctionType = (data) => axios.post('/api/camp/make02/fbAdSetUpdate', data)
