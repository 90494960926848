import { useState } from 'react'
import { Button, Flex, Table, Popconfirm } from 'antd'
import { ColumnType } from 'antd/es/table'
import { type TermType } from '@biz/api'
import { PageTitle } from '@biz/ui'

import { useTermListQuery } from './_queries'
import { useTermsOp } from './_hooks/useTermsOp'
import { DetailViewModal } from './DetailViewModal'
import styles from './TermsListPage.module.scss'

export const TermsListPage: React.FC = () => {
  const { deleteFn } = useTermsOp()
  const { data, isLoading, refetch } = useTermListQuery()
  const { private: privateList, service: serviceList } = data || { private: [], service: [] }
  const [detailData, setDetailData] = useState<{ content: string; title: string; date: string } | null>(null)

  const privateTitle = '개인정보 처리방침'
  const serviceTitle = '광고 서비스 이용약관'
  const columnsFn: (title: string) => ColumnType<TermType>[] = (title) => [
    {
      title: '등록일시',
      dataIndex: 'regTime',
      key: 'regTime',
      fixed: 'left',
      width: 120,
      className: `${styles.columnStyle} ant-table-cell-fix-left-last`,
    },
    {
      title: '버전',
      dataIndex: 'version',
      key: 'version',
      fixed: 'left',
      width: 100,
      className: `${styles.columnStyle} ant-table-cell-fix-left-last`,
    },
    {
      title: '보기',
      width: 40,
      align: 'center',
      className: `${styles.columnStyle} ant-table-cell-fix-left-last`,
      render: (v) => (
        <Button
          type="text"
          onClick={() => setDetailData(v.text ? { content: v?.text || '', title, date: v?.regTime || '' } : null)}
        >
          {'보기'}
        </Button>
      ),
    },
    {
      title: '삭제',
      width: 40,
      align: 'center',
      className: `${styles.columnStyle} ant-table-cell-fix-left-last`,
      render: (v) => (
        <Popconfirm
          placement="bottom"
          title={null}
          description={'삭제하시겠습니까?'}
          okText="확인"
          cancelText="취소"
          onConfirm={() => deleteFn(v?.id).then(() => refetch())}
        >
          <Button type="text">{'삭제'}</Button>
        </Popconfirm>
      ),
    },
  ]

  const titleRender = (title: string, url: string) => () =>
    (
      <Flex justify="space-between">
        <h3 className={styles.sectionHead}>{title}</h3>
        <Button type="primary" href={url}>
          {'신규 작성'}
        </Button>
      </Flex>
    )

  const emptyRender = (caption: string) => (
    <div className={styles.emptyData}>{isLoading ? '리스트를 가져오고 있어요.' : caption}</div>
  )

  return (
    <div className={styles.containTermsListPage}>
      <PageTitle>{`${privateTitle} 및 ${serviceTitle}`}</PageTitle>
      <div className={styles.termSection}>
        <Table<TermType>
          title={titleRender(privateTitle, '/community/systemData/privateDataPost')}
          columns={columnsFn(privateTitle)}
          className={styles.table}
          pagination={{ position: ['bottomCenter'] }}
          locale={{ emptyText: emptyRender(`아직 "${privateTitle}"이 없어요.`) }}
          dataSource={privateList}
        />
        <Table<TermType>
          title={titleRender(serviceTitle, '/community/systemData/termsPost')}
          columns={columnsFn(serviceTitle)}
          className={styles.table}
          pagination={{ position: ['bottomCenter'] }}
          locale={{ emptyText: emptyRender(`아직 "${serviceTitle}"이 없어요.`) }}
          dataSource={serviceList}
        />
      </div>
      <DetailViewModal {...detailData} isOpen={detailData !== null} onClose={() => setDetailData(null)} />
    </div>
  )
}
