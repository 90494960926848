import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { isAxiosError } from 'axios'
// Components
import { loadLoginCheck } from '@biz/query'

import NoticeDetail from './views/makerCommon/notice/NoticeDetail'
import Layout from './layout/Layout'
import NoticePost from './views/community/notice/NoticePost'
import MngMd from './views/mngMd/MngMd'
import MngMdReg from './views/mngMd/MngMdReg'
import SystemData from './views/community/systemData/SystemData'
import PrivateDataPost from './views/community/systemData/PrivateDataPost'
import TermsPost from './views/community/systemData/TermsPost'
import SystemDataForm from './views/maker/home/SystemDataForm'
import Make01 from './views/maker/camp/make01/Make01'
import Make01Req from './views/maker/camp/make01/Make01Req'
import FaqPost from './views/community/faq/FaqPost'
import MngMdUpdate from './views/mngMd/MngMdUpdate'
import BellAlert from './views/makerCommon/bellAlert/BellAlert'
import MakerRpt from './views/rpt/makerRpt/MakerRpt'
import NoticeForAdmin from './views/community/notice/NoticeForAdmin'
import FaqForAdmin from './views/community/faq/FaqForAdmin'
import NoticeForCommon from './views/makerCommon/notice/NoticeForCommon'
import FaqForCommon from './views/makerCommon/faq/FaqForCommon'
import MakerSetting from './views/maker/setting/MakerSetting'
import ExamplePg from './views/example/ExamplePg'
import PgPaymentSuccess from './views/bizmoney/PgPaymentSuccess'
import PgBillingAuthSuccess from './views/bizmoney/PgBillingAuthSuccess'
import MngAdminAccount from './views/mngAdminAccount/MngAdminAccount'
import MngMakerAccount from './views/mngMakerAccount/MngMakerAccount'
import MngAdminRole from './views/mngAdminAccount/MngAdminRole'
import MngAdminIp from './views/mngAdminAccount/MngAdminIp'
import Setting from './views/setting/Setting'
import IntegrationLog from './views/log/IntegrationLog'
import MngMakerAccountDetail from './views/mngMakerAccount/MngMakerAccountDetail'
import Charge from './views/bizmoney/Charge'
import PgPaymentFail from './views/bizmoney/PgPaymentFail'
import BzmMng from './views/bizmoneyAdmin/BzmMng'
import BzmMngDeposit from './views/bizmoneyAdmin/BzmMngDeposit'
import BzmMngMaker from './views/bizmoneyAdmin/BzmMngMaker'
import BzmoneyDailyUsageDetailsForMaker from './views/bizmoney/BzmoneyDailyUsageDetailsForMaker'
import AccAdjustMng from './views/accAdjust/AccAdjustMng'
import BzmoneyUsageSummaryAndDetailsForAdmin from './views/bizmoney/BzmoneyUsageSummaryAndDetailsForAdmin'
import AccAdjustReg from './views/accAdjust/AccAdjustReg'
import BzmMngDepositDetail from './views/bizmoneyAdmin/BzmMngDepositDetail'
import BzmStatus from './views/bizmoney/BzmStatus'
import AccAdjustInfo from './views/accAdjust/AccAdjustInfo'
import AccAdjustTaxbillList from './views/accAdjust/AccAdjustTaxbillList'
import AccAdjustTaxbillInfo from './views/accAdjust/AccAdjustTaxbillInfo'
import MngTaxbill from './views/mngTaxbill/MngTaxbill'
import MngTaxbillDetail from './views/mngTaxbill/MngTaxbillDetail'
import BzmMngDetail from './views/bizmoneyAdmin/BzmMngDetail'
import Integration from './views/mngRpt/Integration'
import Sales from './views/mngRpt/Sales'
import { SalesDpPush } from './AdminReportPages/SalesDpPush'
import Finance from './views/mngRpt/Finance'
import { FinanceDpPush } from './AdminReportPages/FinanceDpPush'
import BzmMngMakerDetail from './views/bizmoneyAdmin/BzmMngMakerDetail'
// import ApiUtil2InterceptorSetup from '../api/ApiUtil2InterceptorSetup'
import BzmMngDebtsByMaker from './views/bizmoneyAdmin/BzmMngDebtsByMaker'
import MdSyncMonitoring from './views/mngMonitoring/MdSyncMonitoring'
import { UsingGuideRedirect } from './views/makerCommon/usingGuide/UsingGuideRedirect'
import { RegisterInquiryRedirect } from './views/makerCommon/RegisterInquiryRedirect'
import { DisplayAdRedirect } from './views/makerCommon/displayAd/DisplayAdRedirect'
import { PushAdRedirect } from './views/makerCommon/pushAd/PushAdRedirect'
import PomLimitManagement from './views/bizmoneyAdmin/postpaidMoney/postpaidMoneyLimit/PomLimitManagement'
import AllowedEntrepreneurManagement from './views/bizmoneyAdmin/postpaidMoney/allowedEntrepreneurManagement/AllowedEntrepreneurManagement'
import AdjustmentOfDeductionTarget from './views/bizmoneyAdmin/postpaidMoney/adjustmentOfDeductionTarget/AjdustmentOfDeductionTarget'
import PomStatus from './views/pom/PomStatus'
import PomDailyUsage from './views/pom/PomDailyUsage'
import PomDailyUsageDetail from './views/pom/PomDailyUsageDetail'
import PomInquiryManagement from './views/bizmoneyAdmin/postpaidMoney/pomInquiry/PomInquiryManagement'
import PomInquiryDetail from './views/bizmoneyAdmin/postpaidMoney/pomInquiry/PomInquiryDetail'
import PomInquiry from './views/pom/PomInquiry'
import PomDailyUsageDetailForAdmin from './views/bizmoneyAdmin/postpaidMoney/detail/PomDailyUsageDetailForAdmin'
import PomDailyUsageForAdmin from './views/bizmoneyAdmin/postpaidMoney/detail/PomDailyUsageForAdmin'
import Home from './HomePage/Home'
import { MngCamp } from './ManageCampaignPage'

import { getDefaultLayoutRoute } from './AdminReportPages/route'

import { getTargetAdRoute } from './TargetCreateLayout/route'
import { getAdminLoginRoute } from './AdminLoginPage/route'
import { getAdminAdManagerRoute } from './AdminAdManagerPages/route'
import { getErrorRoute } from './ErrorLayout/route'
import { getTermsLayoutRoute } from './TermsListPage/route'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 일반 세팅
      // refetchOnWindowFocus: false,
      // 비즈센터 세팅
      // cacheTime: 1000 * 60 * 60 * 24,
      // staleTime: 1000 * 60,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 401) {
        return
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 401) {
        return
      }
    },
  }),
})

const router = createBrowserRouter([
  {
    element: <Layout />,
    loader: loadLoginCheck(queryClient),
    children: [
      {
        path: '/',
        loader: async () => {
          return redirect('/main')
        },
      },
      { path: '/main', element: <Home /> },
      // { path: '/community/systemData', element: <SystemData /> },
      // { path: '/community/systemData/privateDataPost', element: <PrivateDataPost /> },
      // { path: '/community/systemData/termsPost', element: <TermsPost /> },
      { path: '/camp/make01', element: <Make01 /> },
      { path: '/maker/setting', element: <MakerSetting /> },
      { path: '/maker/setting/agreeYn', element: <SystemDataForm /> },
      { path: '/camp/make01/req', element: <Make01Req /> },
      { path: '/rpt/rpt', element: <MakerRpt /> },
      { path: '/community/notice', element: <NoticeForAdmin /> },
      { path: '/community/notice/post', element: <NoticePost /> },
      { path: '/community/notice/modify/:noticeId', element: <NoticePost /> },
      { path: '/community/faq', element: <FaqForAdmin /> },
      { path: '/community/faq/post', element: <FaqPost /> },
      { path: '/community/faq/modify/:faqId', element: <FaqPost /> },
      { path: '/mngTaxBill/mngTaxBill', element: <MngTaxbill /> },
      { path: '/mngTaxBill/mngTaxBill/detail/:taxbillId', element: <MngTaxbillDetail /> },
      { path: '/mngTaxBill/mngTaxBill/adjustDetail', element: <AccAdjustTaxbillInfo /> },
      { path: '/makerCommon/notice/', element: <NoticeForCommon /> },
      { path: '/makerCommon/notice/detail/:noticeId', element: <NoticeDetail /> },
      { path: '/makerCommon/bellAlert', element: <BellAlert /> },
      { path: '/makerCommon/bellAlert/:id', element: <BellAlert /> },
      { path: '/makerCommon/faq', element: <FaqForCommon /> },
      { path: '/makerCommon/registerInquiry', element: <RegisterInquiryRedirect /> },
      { path: '/makerCommon/usingGuide', element: <UsingGuideRedirect /> },
      { path: '/camp/displayAdvertisement', element: <DisplayAdRedirect /> },
      { path: '/camp/pushAdvertisement', element: <PushAdRedirect /> },
      { path: '/mngCamp/mngCamp', element: <MngCamp /> },
      { path: '/mngMd/mngMd', element: <MngMd /> },
      { path: '/mngMd/mngMdReg', element: <MngMdReg /> },
      { path: '/mngMd/MngMdUpdate', element: <MngMdUpdate /> },
      { path: '/mngRpt/integration', element: <Integration /> },
      { path: '/mngRpt/sales', element: <Sales /> },
      { path: '/mngRpt/finance', element: <Finance /> },
      { path: '/pg/examplePg', element: <ExamplePg /> },
      { path: '/pg/payment/success', element: <PgPaymentSuccess /> },
      { path: '/pg/payment/fail', element: <PgPaymentFail /> },
      { path: '/pg/billingAuth/success', element: <PgBillingAuthSuccess /> },
      { path: '/mngAccount/admin', element: <MngAdminAccount /> },
      { path: '/mngAccount/maker', element: <MngMakerAccount /> },
      { path: '/mngAccount/maker/detail/:makerId', element: <MngMakerAccountDetail /> },
      { path: '/mngAccount/admin/role', element: <MngAdminRole /> },
      { path: '/mngAccount/admin/ip', element: <MngAdminIp /> },
      { path: '/admin/setting', element: <Setting /> },
      { path: '/integration/log', element: <IntegrationLog /> },
      { path: '/bzm/charge', element: <Charge /> },
      { path: '/bzm/detail', element: <BzmoneyDailyUsageDetailsForMaker /> },
      { path: '/bzm/status', element: <BzmStatus /> },
      {
        path: '/bzmAdmin/detail',
        element: <BzmoneyUsageSummaryAndDetailsForAdmin />,
        children: [{ path: ':projectId/:makerId', element: <BzmoneyUsageSummaryAndDetailsForAdmin /> }],
      },
      { path: '/pom/dailyUsage', element: <PomDailyUsage /> },
      { path: '/pom/dailyUsage/detail', element: <PomDailyUsageDetail /> },
      { path: '/pom/status', element: <PomStatus /> },
      { path: '/pom/pomInquiry', element: <PomInquiry /> },
      { path: '/bzmAdmin/mng', element: <BzmMng /> },
      { path: '/bzmAdmin/mng/detail/:accRefundId', element: <BzmMngDetail /> },
      { path: '/bzmAdmin/mng/mngDeposit', element: <BzmMngDeposit /> },
      { path: '/bzmAdmin/mng/mngDeposit/detail/:accAtamId', element: <BzmMngDepositDetail /> },
      { path: '/bzmAdmin/mng/mngMaker', element: <BzmMngMaker /> },
      { path: '/bzmAdmin/mng/mngMaker/detail/:makerId/:param', element: <BzmMngMakerDetail /> },
      { path: '/bzmAdmin/mng/mngDebtsByMaker', element: <BzmMngDebtsByMaker /> },
      { path: '/bzmAdmin/postpaidMoneyManagement', element: <AdjustmentOfDeductionTarget /> },
      { path: '/bzmAdmin/postpaidMoneyManagement/AEM', element: <AllowedEntrepreneurManagement /> },
      { path: '/bzmAdmin/postpaidMoneyManagement/PL', element: <PomLimitManagement /> },
      {
        path: '/bzmAdmin/postpaidMoneyManagement/inquiry',
        element: <PomInquiryManagement />,
      },
      {
        path: '/bzmAdmin/postpaidMoneyManagement/inquiry/detail/:makerId/:project2Id/:pomInquiryId',
        element: <PomInquiryDetail />,
      },
      { path: '/bzmAdmin/postpaidMoneyDetail', element: <PomDailyUsageForAdmin /> },
      { path: '/bzmAdmin/postpaidMoneyDetail/detail', element: <PomDailyUsageDetailForAdmin /> },
      { path: '/accAdjust/mng', element: <AccAdjustMng /> },
      { path: '/accAdjust/mng/regist', element: <AccAdjustReg /> },
      { path: '/accAdjust/taxbill', element: <AccAdjustTaxbillList /> },
      { path: '/accAdjust/taxbill/info', element: <AccAdjustTaxbillInfo /> },
      { path: '/accAdjust/mng/info', element: <AccAdjustInfo /> },
      { path: '/mngMonitoring/mdSyncMonitoring', element: <MdSyncMonitoring /> },
    ],
  },
  getTermsLayoutRoute(queryClient),
  getTargetAdRoute(queryClient),
  getDefaultLayoutRoute(queryClient),
  getAdminLoginRoute(queryClient),
  getAdminAdManagerRoute(queryClient),
  getErrorRoute(queryClient),
  {
    path: '/login',
    loader: () => {
      return null
    },
    element: <div></div>,
  },
  {
    path: '/callback/wadiz/api',
    loader: async () => {
      console.log('return to target')
      return null
    },
    element: <div></div>,
  },
])

export const Pages: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} fallbackElement={<div></div>} />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
