import { useRef } from 'react'
import { nanoid } from 'nanoid'
import { Button, Carousel } from 'antd'
import type { CarouselRef } from 'antd/es/carousel'
import { DeleteOutlined } from '@ant-design/icons'

import { ReactComponent as ChevronLeft } from '@common/svg/chevronLeft.svg'
import { ReactComponent as ChevronRight } from '@common/svg/chevronRight.svg'

import type { ImageInfoType } from '@biz/api'
import { type ImageMetaDataType } from '@biz/query'
import { MetaInfo } from './MetaInfo'
import styles from './ImageCarousel.module.scss'

type ImageCarouselProps = {
  list: ImageMetaDataType[]
  onDelete?: (afId: number | string) => void
}
export const ImageCarousel: React.FC<ImageCarouselProps> = ({ list, onDelete }) => {
  const carouselRef = useRef(null)
  const leftMove = () => {
    const ref = carouselRef?.current as CarouselRef | null
    ref?.prev()
  }
  const rightMove = () => {
    const ref = carouselRef?.current as CarouselRef | null
    ref?.next()
  }
  return (
    <section className={styles.ImageCarousel}>
      <div className={styles.carouselContainer}>
        <Carousel ref={carouselRef} dots={{ className: styles.dots }}>
          {(list || []).map((item) => {
            const ext =
              (item.afMeta?.ext || '').toLocaleLowerCase() === 'gif'
                ? 'img'
                : (item.afMeta?.fileDiv || '').toLocaleLowerCase()
            const isImage = !!item.fileName && (ext === 'image' || ext === 'img')
            const isVideo = !!item.fileName && (ext === 'video' || ext === 'vod')
            return (
              <div key={nanoid()} style={{ position: 'relative' }}>
                <div className={styles.imagePreview}>
                  {isImage && <img src={item.publicUrl || item.fileName} alt={item.afName} className={styles.img} />}
                  {isVideo && (
                    <video
                      autoPlay
                      controls
                      muted
                      loop
                      src={`${item.publicUrl || item.fileName}`}
                      className={styles.img}
                    />
                  )}
                </div>
                <div className={styles.toolContainer}>
                  <MetaInfo meta={item.afMeta} />
                  <Button
                    type="text"
                    size="large"
                    icon={<DeleteOutlined />}
                    disabled={!onDelete}
                    onClick={() => onDelete?.((item as ImageInfoType).id)}
                  />
                </div>
              </div>
            )
          })}
        </Carousel>
        {list.length > 1 && (
          <div className={styles.buttonContainer}>
            <Button type="text" icon={<ChevronLeft />} onClick={leftMove} />
            <Button type="text" icon={<ChevronRight />} onClick={rightMove} />
          </div>
        )}
      </div>
    </section>
  )
}
