import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { Cookies } from 'react-cookie'
import qs from 'qs'
import { useNavigate } from 'react-router'
import alertify from 'alertifyjs'
import { ApiUtil, ApiUtil2, loginLocalStorage } from '@biz/api'
import { useLoginStatusQuery } from '@biz/query'
import { initHeader } from '../layout/Header/Header'
import AuthPopUp from '../views/mngAdminAccount/AuthPopUp'

export interface loginForm {
  data: any
  memberId: string
  password: string
}

const Login = () => {
  const url = useLocation()
  const cookies = new Cookies()
  const navigate = useNavigate()
  // 로그인 여부에 따른 상태값
  const { update: setLoginStatus } = useLoginStatusQuery()
  const [isOpenedPopUp, setIsOpenedPopUp] = useState<boolean>(false)
  const { register, handleSubmit, getValues, setValue } = useForm<loginForm>({
    // defaultValues: { memberId: 'admin' },
  })
  let { memberId } = getValues()
  const { password } = getValues()

  const environment = import.meta.env.VITE_ENV || 'live'
  console.log('🪴  environment: ', environment);

  type LoginForm = { memberId: string }
  const onSubmit = ({ memberId }: LoginForm) => {
    // 임시 테스트 코드로 제거 필요 ----------------------------------- 시작
    const testCondition = 'rc-biz.wadiz.kr'.indexOf(location.host) === 0 && memberId.includes('park')

    if (testCondition || 'biz.wadiz.kr'.indexOf(location.host) === 0) {
      ApiUtil2.post<loginForm>('/api/loginCheck/postAuthCode', {
        id: $('#memberId').val(),
        pwd: $('#password').val(),
      })
        .then((response) => {
          if (response.data.data) {
            $('.loader').removeClass('none')
            ApiUtil2.post('/api/loginCheck/generateAuthCode', { id: $('#memberId').val() }).then(() => {
              setIsOpenedPopUp(true)
              $('.loader').addClass('none')
            })
          }
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      // 임시 url
      ApiUtil.post<loginForm>(
        '/api/common/login/doLogin',
        qs.stringify({
          memberId: $('#memberId').val(),
          password: $('#password').val(),
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
        .then((response) => {
          if (response.data) {
            alertify.alert('로그인', `${response.data.data.memberName} 님, 환영합니다.`, () => {
              // 아이디 저장 유무에 따른 쿠키 저장
              const checked = $('#idAutoSave').prop('checked')
              if (checked) cookies.set('idAutoSave', $('#memberId').val())
              else cookies.set('idAutoSave', '')

              loginLocalStorage.setUserInfo(response.data)
              // setLoginStatus(true)
              navigate('/main')
            })
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
    // 임시 테스트 코드로 제거 필요 ----------------------------------- 끝

    // 원래 코드 원복 필요 ---------------------------------- 시작
    // if ('biz.wadiz.kr'.indexOf(location.host) !== 0) {
    //   // location.host가 'biz.wadiz.kr'로 시작하지 않을 때
    //   // 임시 url
    //   ApiUtil.post<loginForm>(
    //     '/api/common/login/doLogin',
    //     qs.stringify({
    //       memberId: $('#memberId').val(),
    //       password: $('#password').val(),
    //     }),
    //     {
    //       headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //       },
    //     }
    //   )
    //     .then((response) => {
    //       if (response.data) {
    //         alertify.alert('로그인', `${response.data.data.memberName} 님, 환영합니다.`, () => {
    //           // 아이디 저장 유무에 따른 쿠키 저장
    //           const checked = $('#idAutoSave').prop('checked')
    //           if (checked) cookies.set('idAutoSave', $('#memberId').val())
    //           else cookies.set('idAutoSave', '')

    //           loginLocalStorage.setUserInfo(response.data)
    //           // setLoginStatus(true)
    //           navigate('/main')
    //         })
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(e)
    //     })
    // } else {
    //   ApiUtil2.post<loginForm>('/api/loginCheck/postAuthCode', {
    //     id: $('#memberId').val(),
    //     pwd: $('#password').val(),
    //   })
    //     .then((response) => {
    //       if (response.data.data) {
    //         $('.loader').removeClass('none')
    //         ApiUtil2.post('/api/loginCheck/generateAuthCode', { id: $('#memberId').val() }).then(() => {
    //           setIsOpenedPopUp(true)
    //           $('.loader').addClass('none')
    //         })
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(e)
    //     })
    // }
    // 원래 코드 원복 필요 -------------------------------------------- 끝
  }

  // 벨리데이션시 사용
  const onErr = (error: any) => {
    if (error.memberId !== undefined) {
      if (error.memberId.message !== '') {
        alertify.error(error.memberId.message)
      }
    }
    if (error.password !== undefined) {
      if (error.password.message !== '') {
        alertify.error(error.password.message)
      }
    }
  }
  useEffect(() => {
    if (url.pathname === '/common/login') {
      initHeader()
    }
    memberId = cookies.get('idAutoSave')
    if (memberId !== '' && memberId !== undefined) {
      $('#idAutoSave').prop('checked', true)
      setValue('memberId', memberId)
      $('#password').focus()
    } else {
      $('#memberId').focus()
    }
  }, [])
  return (
    <Fragment>
      {isOpenedPopUp ? (
        <AuthPopUp
          setIsOpenedPopUp={setIsOpenedPopUp}
          authType={'loginAuth'}
          setLoginStatus={setLoginStatus}
          memberId={memberId}
          password={password}
        />
      ) : null}
      <section className="wrap-section flex-container-center">
        <div className="inner-section">
          <div className="box-top">
            <h2 className="fz-30 fc-1 fw-bold">와디즈 비즈센터 관리자 로그인</h2>
            <p className="fz-16 fc-1 fw-light">
              <span className="fw-medium">와디즈 비즈센터</span>에 오신 것을 환영합니다.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit, onErr)}>
            <div className="box-middle">
              <div className="input-group large expand">
                <div className="inner-input-group">
                  <input
                    type="text"
                    id="memberId"
                    {...register('memberId', {
                      required: { value: true, message: '아이디는 필수 입력 값입니다.' },
                      maxLength: { value: 20, message: '최대값을 초과하였습니다.' },
                    })}
                    className="tf-comm"
                    placeholder="ID"
                  />
                </div>
                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
              </div>
              <div className="input-group large expand">
                <div className="inner-input-group">
                  <input
                    type="password"
                    id="password"
                    {...register('password', { required: { value: true, message: '비밀번호는 필수 입력 값입니다.' } })}
                    className="tf-comm"
                    placeholder="Password"
                  />
                </div>
                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
              </div>
              <div className="comp-checkbox">
                <input type="checkbox" id="idAutoSave" />
                <label htmlFor="idAutoSave">ID 저장하기</label>
              </div>
            </div>
            <div className="box-bottom">
              <button type="submit" className="btn btn-primary xlarge expand">
                로그인
              </button>
            </div>
          </form>
        </div>
        <div className="loader none">
          <div className="stage">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <p className="txt-loader">LOADING</p>
        </div>
      </section>
    </Fragment>
  )
}

export default Login
